<template>
  <div class="appbody">
    <div class="title">
      <div>{{ resumeData.start_date }}-{{ resumeData.end_date }}</div>
      <div>{{ status ? resumeData.edu_college : "" }}</div>
      <div>
        {{ status ? resumeData.edu_major : "" }}({{
          status ? resumeData.edu_degree : ""
        }})
      </div>
    </div>
    <!-- 内容 -->
    <!-- <div class="jobBody">
      <div>专业成绩：<span></span></div>
      <div>主修课程：<span></span></div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "JobCard",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
    resumeData: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang='scss' scoped>
.appbody {
  padding: 10px 0 0 5px;
  font-family: "方正黑体";
  width: 100%;
  .title {
    width: 100%;
    > div {
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      width: 33%;
    }
  }
  .jobBody {
    margin-top: 10px;
    padding-bottom: 5px;
    font-weight: 600;
    > div {
      line-height: 25px;
      > span {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
.ul > li {
  margin: 10px 0;
  font-size: 14px;
}
</style>