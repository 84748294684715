/**
 * 简历字段解析
 * 将解析api接口的字段，转换为本地定义字段
 * 接收一个参数data，data为api接口返回字段
 * 返回一个newData  newData如下定义
**/
import { nanoid } from "nanoid"; //唯一id生成
export function parseBytes(data, dictCodeList) {
    console.info('解析字段', data)
    let newData = {
        addModel: {},
        workExperience: [],
        projectExperience: [],
        eduExperience: [],
    }

    let e = JSON.parse(JSON.stringify(data));
    newData.addModel.emali = e.email;
    newData.addModel.name = e.name;
    newData.addModel.phone = e.phone;
    newData.addModel.wechat = e.weixin;
    // 字典
    newData.addModel.politicalStatus = e.polit_status;
    newData.addModel.gender = e.gender;
    newData.addModel.workingPosition = e.work_status;

    newData.addModel.age = e.age;
    // 头像
    newData.addModel.head_pic = e.avatar_data;
    if (e.work_year_norm) {
        newData.addModel.jobYear = parseInt(e.work_year_norm);
    }

    if (e.work_start_time) {
        newData.addModel.firstJobdate = e.work_start_time.split(".").join("-");
        if (newData.addModel.firstJobdate.split("-").length < 3) {
            newData.addModel.firstJobdate = newData.addModel.firstJobdate + "-01";
        }
        if (newData.addModel.firstJobdate.split("-").length < 3) {
            newData.addModel.firstJobdate = newData.addModel.firstJobdate + "-01";
        }
    }

    if (e.degree) {
        dictCodeList.education.forEach((item) => {
            if (e.degree.includes(item.dictLabel)) {
                newData.addModel.degree = item.dictCodeStr;
            }
        });
    }

    newData.addModel.exPosition = e.expect_job;
    newData.addModel.techCap = e.cont_job_skill;
    newData.addModel.selfEval = e.cont_my_desc;
    newData.addModel.qq = e.qq;
    if (e.birthday) {
        newData.addModel.brithdate = e.birthday.split(".").join("-");
        if (newData.addModel.brithdate.split("-").length < 3) {
            newData.addModel.brithdate = newData.addModel.brithdate + "-01";
        }
        if (newData.addModel.brithdate.split("-").length < 3) {
            newData.addModel.brithdate = newData.addModel.brithdate + "-01";
        }
    }

    newData.addModel.graduSchool = e.college;
    dictCodeList.nation.forEach((item) => {
        if (item.dictLabel === e.race) {
            newData.addModel.nation = item.dictCodeStr;
        }
    });

    newData.addModel.major = e.major;
    newData.addModel.honor = e.cont_award;

    newData.addModel = JSON.parse(JSON.stringify(newData.addModel));
    // 
    newData.workExperience = [];
    e.job_exp_objs.forEach((item) => {
        item.id = nanoid(6);
        let newItem = item;
        if (item.start_date && item.end_date) {
            let startDate = item.start_date;
            let endDate = item.end_date;

            if (startDate.split(".").length < 3) {
                startDate = startDate + ".01";
            }
            if (endDate.split(".").length < 3) {
                endDate = endDate + ".01";
            }

            startDate = startDate.split(".").join("-");
            endDate = endDate.split(".").join("-");

            newItem.job_date_start = startDate
            newItem.job_date_end = endDate
        }
        newData.workExperience.push(newItem);
    });

    // 项目经历
    newData.projectExperience = [];
    e.proj_exp_objs.forEach((item) => {
        item.id = nanoid(6);
        let newItem = item;
        if (item.start_date && item.end_date) {
            let startDate = item.start_date;
            let endDate = item.end_date;
            if (startDate.split(".").length < 3) {
                startDate = startDate + ".01";
            }
            if (endDate.split(".").length < 3) {
                endDate = endDate + ".01";
            }

            startDate = startDate.split(".").join("-");
            endDate = endDate.split(".").join("-");

            newItem.prodate_start = startDate
            newItem.prodate_end = endDate
        }
        newData.projectExperience.push(newItem);
    });

    // 教育经历
    newData.eduExperience = [];
    e.education_objs.forEach((item) => {
        item.id = nanoid(6);
        let newItem = item;
        if (item.start_date && item.end_date) {
            let startDate = item.start_date;
            let endDate = item.end_date;

            if (startDate.split(".").length < 3) {
                startDate = startDate + ".01";
            }
            if (endDate.split(".").length < 3) {
                endDate = endDate + ".01";
            }

            startDate = startDate.split(".").join("-");
            endDate = endDate.split(".").join("-");
            newItem.edudate_start = startDate
            newItem.edudate_end = endDate
        }
        newData.eduExperience.push(newItem);
    });

    return newData
}