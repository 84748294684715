<template>
  <div class="appbody">
    <div class="title">
      <div>
        {{ status ? oData.start_date : oData.start_date }}—{{
          status ? oData.end_date : oData.end_date
        }}
      </div>
      <div>{{ status ? oData.job_cpy : oData.proj_name }}</div>
      <div>{{ status ? oData.job_position : oData.proj_position }}</div>
    </div>
    <div style="margin-top: 10px">
      {{ status ? oData.job_content : oData.proj_content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemCard",
  data() {
    return {};
  },
  props: {
    oData: {
      type: Object,
      default: {},
    },
    status: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  activated() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.appbody {
  margin-top: 20px;
  padding: 10px 0 0 5px;
  font-family: "方正黑体";
  width: 100%;
  .title {
    width: 100%;

    > div {
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      width: 33%;
    }
  }
}
.ul > li {
  margin: 10px 0;
  font-size: 14px;
}
</style>