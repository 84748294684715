<template>
  <div id="app">
    <index v-if="status" @nextBtn="nextTipe" ref="index" />
    <detial v-else @back="back" />
  </div>
</template>

<script>
import index from "@/components/index.vue";
import detial from "@/components/job/detial.vue";
export default {
  name: "App",
  components: {
    index,
    detial,
  },
  data() {
    return {
      status: true,
      detial: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  activated() {},
  methods: {
    nextTipe() {
      this.status = false;
      console.log("aaa");
    },
    back() {
      localStorage.removeItem("id");
      this.status = true;
      this.$refs.index.handleClose();
    },
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
  padding: 0;
  margin: 0;
}
</style>
