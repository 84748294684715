var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-body"},[_c('el-card',[_c('div',{staticClass:"body"},[_c('div',{staticClass:"left"},[_c('el-row',[_c('span',{staticClass:"title"},[_vm._v("智能求职")]),_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.identifyBtn}},[_vm._v("识别")]),_c('el-button',{attrs:{"type":"primary","round":"","disabled":!_vm.machType},on:{"click":_vm.matchJobBtn}},[_vm._v("匹配职位")]),_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.back}},[_vm._v("退出")])],1),_c('el-divider'),_c('div',{staticClass:"resumeBox"},[_c('div',{staticClass:"line"}),_c('el-row',[_c('el-col',{attrs:{"span":17}},[_c('div',[_c('ALabel',{attrs:{"label":"职业期望","currently":"","resumeData":_vm.resumeData}}),_c('Expecta',{attrs:{"resumeData":_vm.resumeData}})],1)]),_c('el-col',{attrs:{"span":6,"offset":1}},[_c('el-image')],1)],1),_c('div',[_c('ALabel',{attrs:{"label":"教育背景"}}),(
                _vm.resumeData.eduExperience &&
                _vm.resumeData.eduExperience.length > 0
              )?_c('div',_vm._l((JSON.parse(_vm.resumeData.eduExperience)),function(item){return _c('JobCard',{key:item.id,attrs:{"status":"","resumeData":item}})}),1):_vm._e()],1),_c('div',[_c('ALabel',{attrs:{"label":"工作经验"}}),(
                _vm.resumeData.workExperience &&
                _vm.resumeData.workExperience.length > 0
              )?_c('div',_vm._l((JSON.parse(_vm.resumeData.workExperience)),function(e){return _c('ItemCard',{key:e.id,attrs:{"oData":e,"status":""}})}),1):_vm._e()],1),_c('div',[_c('ALabel',{attrs:{"label":"项目经历"}}),(
                _vm.resumeData.projectExperience &&
                _vm.resumeData.projectExperience.length > 0
              )?_c('div',_vm._l((JSON.parse(_vm.resumeData.projectExperience)),function(e){return _c('ItemCard',{key:e.id,attrs:{"oData":e}})}),1):_vm._e()],1),_c('div',[_c('ALabel',{attrs:{"label":"技能特长"}}),_vm._v(" "+_vm._s(_vm.resumeData.techCap)+" ")],1),_c('div',[_c('ALabel',{attrs:{"label":"自我评价"}}),_c('div',{staticClass:"self"},[_vm._v(" "+_vm._s(_vm.resumeData.selfEval)+" ")])],1)],1)],1),_c('div',{staticClass:"right"},[(_vm.viewFile.url.includes('.pdf'))?_c('iFrame',{attrs:{"src":_vm.$env.VUE_APP_BASE_API +
            '/upload/show?path=' +
            encodeURIComponent(_vm.viewFile.url)}}):(
            _vm.viewFile.url.includes('.jpg') ||
            _vm.viewFile.url.includes('.png') ||
            _vm.viewFile.url.includes('.JPG') ||
            _vm.viewFile.url.includes('.PNG')
          )?_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.$env.VUE_APP_BASE_API +
            '/upload/showimg?path=' +
            encodeURIComponent(_vm.viewFile.url)}}):_vm._e()],1)])]),_c('el-dialog',{attrs:{"visible":_vm.mach.sync,"width":"900px"},on:{"update:visible":function($event){return _vm.$set(_vm.mach, "sync", $event)}}},[_c('el-table',{staticStyle:{"margin-top":"15px"},attrs:{"data":_vm.jobTableList,"height":"600"}},[_c('el-table-column',{attrs:{"prop":"jobName","label":"职位名称","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"firstDegree","label":"学历"}}),_c('el-table-column',{attrs:{"prop":"major","label":"专业","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"prop":"companyName","label":"招聘单位","show-overflow-tooltip":"","min-width":"110"}}),_c('el-table-column',{attrs:{"label":"匹配度","prop":"score"}}),_c('el-table-column',{attrs:{"label":"操作","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.pushJobsBtn(row)}}},[_vm._v("自荐")])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }