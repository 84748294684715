<template>
  <div class="app-body">
    <el-card>
      <div class="body">
        <!-- 识别块 -->
        <div class="left">
          <el-row>
            <span class="title">智能求职</span>
            <el-button type="primary" round @click="identifyBtn"
              >识别</el-button
            >
            <el-button
              type="primary"
              round
              @click="matchJobBtn"
              :disabled="!machType"
              >匹配职位</el-button
            ><el-button type="primary" round @click="back">退出</el-button>
          </el-row>

          <el-divider />

          <div class="resumeBox">
            <div class="line"></div>
            <!-- 职业期望 -->
            <el-row>
              <el-col :span="17">
                <div>
                  <ALabel label="职业期望" currently :resumeData="resumeData" />
                  <Expecta :resumeData="resumeData" /></div
              ></el-col>
              <el-col :span="6" :offset="1">
                <el-image />
              </el-col>
            </el-row>
            <!-- 教育背景 -->
            <div>
              <ALabel label="教育背景" />
              <div
                v-if="
                  resumeData.eduExperience &&
                  resumeData.eduExperience.length > 0
                "
              >
                <JobCard
                  status
                  v-for="item in JSON.parse(resumeData.eduExperience)"
                  :key="item.id"
                  :resumeData="item"
                />
              </div>
            </div>
            <!-- 工作经验 -->
            <div>
              <ALabel label="工作经验" />
              <div
                v-if="
                  resumeData.workExperience &&
                  resumeData.workExperience.length > 0
                "
              >
                <ItemCard
                  v-for="e in JSON.parse(resumeData.workExperience)"
                  :key="e.id"
                  :oData="e"
                  status
                />
              </div>
            </div>
            <!-- 项目经历 -->
            <div>
              <ALabel label="项目经历" />
              <div
                v-if="
                  resumeData.projectExperience &&
                  resumeData.projectExperience.length > 0
                "
              >
                <ItemCard
                  v-for="e in JSON.parse(resumeData.projectExperience)"
                  :key="e.id"
                  :oData="e"
                />
              </div>
            </div>
            <!-- 技能特长 -->
            <div>
              <ALabel label="技能特长" />
              {{ resumeData.techCap }}
            </div>
            <!-- 自我评价 -->
            <div>
              <ALabel label="自我评价" />
              <div class="self">
                {{ resumeData.selfEval }}
              </div>
            </div>
          </div>
        </div>
        <!-- 预览块 -->
        <div class="right">
          <iFrame
            v-if="viewFile.url.includes('.pdf')"
            :src="
              $env.VUE_APP_BASE_API +
              '/upload/show?path=' +
              encodeURIComponent(viewFile.url)
            "
          />
          <img
            style="width: 100%"
            v-else-if="
              viewFile.url.includes('.jpg') ||
              viewFile.url.includes('.png') ||
              viewFile.url.includes('.JPG') ||
              viewFile.url.includes('.PNG')
            "
            :src="
              $env.VUE_APP_BASE_API +
              '/upload/showimg?path=' +
              encodeURIComponent(viewFile.url)
            "
          />
        </div>
      </div>
    </el-card>

    <!-- 匹配 -->
    <el-dialog :visible.sync="mach.sync" width="900px">
      <el-table style="margin-top: 15px" :data="jobTableList" height="600"
        ><el-table-column
          prop="jobName"
          label="职位名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="firstDegree" label="学历"></el-table-column
        ><el-table-column
          prop="major"
          label="专业"
          show-overflow-tooltip
        ></el-table-column
        ><el-table-column
          prop="companyName"
          label="招聘单位"
          show-overflow-tooltip
          min-width="110"
        ></el-table-column>
        <el-table-column label="匹配度" prop="score" />
        <el-table-column label="操作" width="60">
          <template slot-scope="{ row }">
            <!-- <el-button
              :disabled="row.isAdd === 'no'"
              type="text"
              @click="pushJobsBtn(row)"
              >{{ row.isAdd === "no" ? "不可推荐" : "已自荐" }}</el-button
            > -->
            <el-button type="text" @click="pushJobsBtn(row)">自荐</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import ItemCard from "./components/ItemCard.vue";
import ALabel from "./components/ALabel.vue";
import JobCard from "./components/JobCard.vue";
import Expecta from "./components/Expecta.vue";
import { parseBytes } from "./ParseBytes";
import {
  selfPush,
  infoApi,
  matchRequirementsApi,
  resumeAddApi,
  parserApi,
} from "@/api/index";
import iFrame from "@/components/iFrame/index";
export default {
  name: "job",
  data() {
    return {
      viewFile: {
        url: "",
      },

      id: "",
      resumeData: {},
      jobTableList: [],
      mach: { sync: false },
      loading: null,
      status: false,
      machType: false,
    };
  },
  components: { ALabel, JobCard, ItemCard, Expecta, iFrame },
  computed: {},
  watch: {},
  created() {
    this.id = localStorage.getItem("id");
    this.getDetial();
    this.machType = false;
  },
  activated() {},
  methods: {
    async pushJobsBtn(row) {
      let params = {
        resumeId: this.id,
        requirementsId: row.requirementsId,
      };
      let res = await selfPush(params);
      if (res && res.code === 200) {
        this.$message.success("已自荐");
      }
    },
    async getDetial() {
      let res = await infoApi({ id: this.id });
      if (res && res.code === 200) {
        console.log("myResume", JSON.parse(JSON.stringify(res.data)));
        this.resumeData = JSON.parse(JSON.stringify(res.data));
        this.viewFile = JSON.parse(this.resumeData.uploadFiles)[0];
      }
    },
    async matchJobBtn() {
      let params = {
        resumeId: this.id,
      };
      this.loading = this.$loading({
        lock: true,
        text: "匹配中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await matchRequirementsApi(params);
      if (res && res.code === 200) {
        this.jobTableList = res.data;
        this.mach.sync = true;
        this.loading.close();
      }
    },
    async identifyBtn() {
      if (this.status === false) {
        this.loading = this.$loading({
          lock: true,
          text: "简历识别中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let params = {
          path: encodeURIComponent(this.viewFile.url),
        };
        let res = await parserApi(params);
        if (res && res.code === 200) {
          this.status = true;
          this.loading.close();
          let dictCode = {
            education: [],
            nation: [],
          };
          let newData = parseBytes(res.data, dictCode);
          console.log(newData);
          let newResumeData = {
            ...newData.addModel,
            eduExperience: newData.eduExperience,
            projectExperience: newData.projectExperience,
            workExperience: newData.workExperience,
          };
          this.resumeData = JSON.parse(JSON.stringify(newResumeData));
          this.resumeData.id = this.id;
          this.saveBtn();
        }
      } else {
        this.$message.error("太过频繁，请稍后再识别");
      }
    },
    async saveBtn() {
      let params = JSON.parse(JSON.stringify(this.resumeData));
      params.resumeType = "2";
      let res = await resumeAddApi(params);
      if (res && res.code === 200) {
        this.$message.success("识别成功");
        this.machType = true;
        this.getDetial();
      } else {
        this.$message.error("识别失败");
      }
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang='scss' scoped>
.app-body {
  font-family: "方正黑体";
  padding: 20px;
  .body {
    width: 100%;
    display: flex;
    .left {
      flex: 1;
      .btn {
        margin: 10px 0;
      }
      .msgurl {
        margin: 5px 0;
        font-size: 14px;
      }
      .urlList {
        margin-left: 20px;
        color: blue;
        font-size: 14px;
        .item {
          margin-left: 10px;
          padding: 2px;
          border-radius: 2px;
          .icon {
            margin-right: 2px;
            font-weight: 600;
            color: black;
          }
        }
        .item:hover {
          cursor: pointer;
          background-color: #d8d4d4;
        }
      }
      .resumeBox {
        border: 8px solid #5270b0;
        min-height: 75vh;
        > div {
          width: 96%;
          margin: 10px auto;
        }
        .line {
          height: 40px;

          background-color: #5270b0;
        }
      }
      .self {
        line-height: 25px;
        font-size: 14px;
        padding: 5px 20px;
      }
    }
    .right {
      flex: 1;
      padding-top: 60px;
      margin-left: 10px;
      .right_title {
        margin-top: 70px;
        height: 30px;
        margin-bottom: 10px;
        font-weight: 600;
      }
    }
  }
}
::v-deep .el-divider--horizontal {
  margin: 10px 0;
}
.ul > li {
  margin: 10px 0;
  font-size: 14px;
}
.titleFont {
  font-weight: 600;
}
.title {
  margin-right: 20px;
  font-weight: 600;
  font-size: 22px;
  color: #a19d9d;
}
</style>
