<template>
  <div class="label">
    <div class="title">
      {{ label }}
    </div>
    <div
      class="label_tap"
      v-if="
        currently &&
        resumeData.workExperience &&
        resumeData.workExperience.length > 0
      "
    >
      <span>目前薪资：{{ resumeData.exAnnualSalary }}</span
      ><span>目前行业：{{ resumeData.exIndustry }}</span
      ><span
        >目前职位：{{
          JSON.parse(resumeData.workExperience)[0].job_position
        }}</span
      >
    </div>
    <div class="labelLine"></div>
  </div>
</template>

<script>
export default {
  name: "ALabel",
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    currently: {
      type: Boolean,
      default: false,
    },
    resumeData: {
      type: Object,
      default: {},
    },
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  activated() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.label {
  font-family: "方正黑体";
  width: 100%;
  height: 50px;
  position: relative;
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #5270ae;
    padding-bottom: 4px;
    width: 120px;
    text-align: center;
    border-bottom: 4px solid #5270ae;
  }
  .labelLine {
    height: 2px;
    width: 100%;
    margin-top: -3px;
    background-color: #5270ae;
  }
  .label_tap {
    position: absolute;
    top: 15px;
    color: #5270ae;
    font-weight: 600;
    font-size: 14px;
    left: 120px;
    > span {
      margin-left: 20px;
    }
  }
}
</style>