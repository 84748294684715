import request from '@/utils/request'

// 查询简历信息 
export function myResumeApi(data) {
    return request({
        url: '/resume/myResume',
        method: 'post',
        data
    })
}
// 根据id查询简历详情
export function infoApi(data) {
    return request({
        url: '/resume/info',
        method: 'post',
        data
    })
}

// 匹配
export function matchRequirementsApi(data) {
    return request({
        url: '/resume/matchRequirements',
        method: 'post',
        data
    })
}

// 保存简历
export function resumeAddApi(data) {
    return request({
        url: '/resume/updateMyResume',
        method: 'post',
        data
    })
}

// 简历识别
export function parserApi(data) {
    return request({
        url: '/parser/path',
        method: 'post',
        params: data
    })
}

// 自荐
export function selfPush(data) {
    return request({
        url: '/candidate/selfPush ',
        method: 'post',
        data: data
    })
}