<template>
  <div class="appbody">
    <el-row>
      <el-col :span="7"
        ><i class="el-icon-user icon" />姓名：<span>{{
          resumeData.name
        }}</span></el-col
      >
      <el-col :span="8"
        ><i class="icon el-icon-date" />年龄：<span>{{
          resumeData.age
        }}</span></el-col
      >
      <el-col :span="9"
        ><i class="icon el-icon-female" />性别：<span>{{
          resumeData.gender
        }}</span></el-col
      >
      <el-col :span="7"
        ><i class="icon el-icon-collection" />学历：<span>{{
          resumeData.degree
        }}</span></el-col
      >
      <el-col :span="8"
        ><i class="icon el-icon-location-outline" />籍贯：<span>{{
          resumeData.birthPlace
        }}</span></el-col
      >
      <el-col :span="9"
        ><i class="icon el-icon-suitcase-1" />工作年限：<span>{{
          resumeData.jobYear
        }}</span></el-col
      >
      <el-col :span="7"
        ><i class="icon el-icon-mic" />语言：<span>{{
          resumeData.language
        }}</span></el-col
      >
      <el-col :span="8"
        ><i class="icon el-icon-phone-outline" />联系电话：<span
          style="font-size: 12px"
          >{{ resumeData.phone }}</span
        ></el-col
      >
      <el-col :span="9"
        ><i class="icon el-icon-message" />联系邮箱：<span
          style="font-size: 12px"
        >
          {{ resumeData.emali }}</span
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "expectation",
  data() {
    return {};
  },
  props: {
    resumeData: {
      type: Object,
      default: {},
    },
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  activated() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.appbody {
  width: 100%;
  padding: 10px 0 0 5px;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  span {
    font-weight: 400;
  }
  .icon {
    color: #5270ae;
    font-weight: 600;
    margin-right: 5px;
  }
}
</style>