<template>
  <div class="app-body">
    <div class="title">
      <div>数字经济人才招聘引才平台</div>
      <div>人才端智能求职</div>
    </div>
    <div class="card">
      <el-form
        ref="form"
        :rules="rules"
        :model="query"
        inline
        label-width="90px"
        labelw-postion="right"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="query.name" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="query.phone" />
        </el-form-item>
      </el-form>
      <div class="button">
        <el-button type="primary" @click="searchBtn" v-if="status">
          查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
        >
        <el-button type="primary" @click="nextBtn" v-else> 下一步</el-button>
      </div>
    </div>
    <div class="tip">
      温馨提示：为保护隐私，只有当姓名和手机号同时输入正确且预先在上传简历的情况下才能查询到您的简历
    </div>

    <el-dialog
      :visible.sync="dialog.sync"
      width="800px"
      :before-close="handleClose"
      top="40vh"
    >
      <div class="msg">查询到您的简历编号为：{{ dialog.num }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="nextBtn">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { myResumeApi } from "@/api/index.js";
export default {
  name: "index",
  data() {
    return {
      dialog: {
        sync: false,
        num: "256332145871",
      },

      status: true,
      query: {
        name: "",
        phone: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {
    this.handleClose();
  },
  activated() {},
  methods: {
    handleClose() {
      this.query = {
        name: "",
        phone: "",
      };
      this.dialog = {
        sync: false,
        num: "",
      };
    },
    searchBtn() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.searchPost();
        } else {
          return false;
        }
      });
    },
    async searchPost() {
      let res = await myResumeApi(this.query);
      if (res && res.code === 200) {
        if (res.data) {
          localStorage.setItem("id", res.data.id);
          this.dialog = {
            sync: true,
            num: res.data.resumeNo,
          };
        } else {
          this.$message.error("暂未查询到数据");
        }
      }
    },
    nextBtn() {
      this.handleClose();
      setTimeout(() => {
        this.$emit("nextBtn");
      }, 500);
    },
  },
};
</script>

<style lang='scss' scoped>
.app-body {
  position: absolute;
  background-image: url("../assets/bg.png");
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  .title {
    color: #fff;
    text-align: center;
    position: relative;
    top: 24vh;
    font-size: 20px;
    font-weight: 600;
    > div {
      height: 30px;
      line-height: 30px;
    }
  }
  .card {
    width: 36vw;
    background-color: #377dce;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    top: 40vh;
    left: 32vw;
    .button {
      margin-top: 20px;
      text-align: center;
    }
  }
  .tip {
    position: relative;
    top: 40vh;
    left: 32vw;
    margin-top: 20px;
    color: #fff;
    font-size: 0.7vw;
    width: 36vw;
    text-align: center;
  }
}
::v-deep .el-form-item__label {
  color: white !important;
  font-weight: 600;
  font-size: 20px;
}
::v-deep .el-input {
  font-size: 16px;
}
::v-deep .button .el-button--primary {
  background-color: #2560d2;
  border-color: #2560d2;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  width: 10vw;
}
.msg {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
</style>